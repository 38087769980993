import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { commonInputProps } from './commonProps';

const Input = ({ name, control, ...rest }) => {
  return (
    <Controller
      render={({ field, fieldState }) => {
        return (
          <TextField {...field} {...rest} InputProps={commonInputProps(fieldState, field.value, rest.InputProps)} />
        );
      }}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

export default Input;
