import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import commonProps from './commonProps';

const StyledGridItem = styled(Grid)`
  :empty {
    margin-top: -64px;
    margin-bottom: 8px;
  }
`;

const Form = ({ methods, onSubmit, spacing = 3, children, inputProps = {} }) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={spacing}>
          {React.Children.map(children, (child) => {
            return child?.props?.name ? (
              <Grid
                item
                xs={child.props?.xs || 12}
                md={child.props?.md || 12}
                lg={child.props?.lg}
                xl={child?.props?.xl}
              >
                {React.createElement(child.type, {
                  ...{
                    ...child.props,
                    ...commonProps(child.props.name, methods),
                    ...inputProps,
                  },
                })}
              </Grid>
            ) : (
              <StyledGridItem item xs={12}>
                {React.createElement(child.type, {
                  ...{
                    ...child.props,
                    control: methods.control,
                    ...inputProps,
                  },
                })}
              </StyledGridItem>
            );
          })}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Form;
