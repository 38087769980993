import React from 'react';
import styled from '@emotion/styled';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { Controller } from 'react-hook-form';

const Stepper = ({ name, unit, control, max = 60, min = 1 }) => {
  const handleNext = (value) => Math.min(Number.parseInt(value, 10) + 1, max);
  const handleBack = (value) => Math.max(Number.parseInt(value, 10) - 1, min);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Container>
            <StepButton size="small" onClick={() => field.onChange(handleBack(field.value))}>
              <FiMinus color="#fff" />
            </StepButton>
            <Typography variant="body1">
              {field.value}{' '}
              <Typography variant="subtitle1" color="textSecondary" component="span">
                {unit}
              </Typography>
            </Typography>
            <Tooltip title={`Maksimalno ${max} ${unit}`}>
              <StepButton size="small" onClick={() => field.onChange(handleNext(field.value))}>
                <FiPlus color="#fff" />
              </StepButton>
            </Tooltip>
          </Container>
        );
      }}
    />
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 52px;
  padding: 0 24px;

  p {
    font-size: 24px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
    p {
      font-size: 20px;
    }
  }

  @media (max-width: 320px) {
    p {
      font-size: 16px;
    }
  }
`;

const StepButton = styled(IconButton)`
  height: 38px;
  width: 38px;
  border-radius: 50%;
  margin: 0 8px;
  &,
  &.Mui-disabled,
  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
  :hover {
    transform: scale(1.15);
  }

  ${({ theme }) => theme.breakpoints.down('xl')} {
    margin: 0 4px;
  }
`;

export default Stepper;
