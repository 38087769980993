import { atom, selector } from 'recoil';

const fileState = atom({
  key: 'fileState',
  default: [],
});

const fileLengthState = selector({
  key: 'fileLengthState',
  get: ({ get }) => {
    const files = get(fileState);
    return files?.length;
  },
});

export { fileState, fileLengthState };
