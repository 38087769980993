import axios from 'axios';
import firebase from 'firebase/app';

const instance = axios.create({
  baseURL: process.env.API_ENDPOINT || 'http://localhost:5000/api/',
  timeout: 30000,
});

instance.interceptors.request.use(
  async (config) => {
    const token = await firebase.auth()?.currentUser?.getIdToken();
    if (token) config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;