import { number, string, boolean } from 'yup';

export const checkJmbg = (jmbg) => {
  if (jmbg) {
    const len = jmbg.length;
    const validChars = jmbg.match(/^[0-9]+$/);
    if (len !== 13 || !validChars) return false;
    const j = jmbg.split('').map((d) => parseInt(d, 10));
    let v =
      11 -
      ((7 * (j[0] + j[6]) +
        6 * (j[1] + j[7]) +
        5 * (j[2] + j[8]) +
        4 * (j[3] + j[9]) +
        3 * (j[4] + j[10]) +
        2 * (j[5] + j[11])) %
        11);
    if (v > 9) v = 0;
    return v === j[12];
  }
  return false;
};
export const checkPhone = (phone) => {
  if (phone) {
    return /^\+\d{3}\s\d{2}\s\d{3}\s\d{3,4}$/.test(phone);
  }
  return true;
};

export const checkDocumentsCategories = (documents) => {
  const documentCategories = ['lkFiles', 'residenceProofFiles', 'incomeProofFiles', 'crkAgreement'];
  const categoryCount = documentCategories.reduce((count, category) => ({ ...count, [category]: 0 }), {});
  documents.forEach(({ category }) => {
    if (documentCategories.includes(category)) categoryCount[category] += 1;
  });
  return Object.keys(categoryCount).every((category) => categoryCount[category]);
};

export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

export const requiredNumber = (name) =>
  number()
    .typeError(`${name || 'Polje'} mora biti broj`)
    .required(`Polje ne smije biti prazno`)
    .positive(`Minimalna vrijednost je 1`);

export const optionalNumber = () =>
  number().transform(emptyStringToNull).nullable().positive('Minimalna vrijednost je 1');

export const requiredNullableNumber = (name) =>
  number()
    .typeError(`${name || 'Polje'} mora biti broj`)
    .required('Polje ne smije biti prazno')
    .test('Value check', 'Minimalna vrijednost je 0', (val) => val >= 0)
    .max(6665533, `Maksimalna vrijednost je 6665533`);

export const optionalNullableNumber = () =>
  number()
    .typeError('Molimo unesite broj')
    .test('Value check', 'Minimalna vrijednost je 0', (val) => val >= 0)
    .max(6665533, `Maksimalna vrijednost je 6665533`)
    .transform(emptyStringToNull)
    .nullable();

export const requiredString = (name) =>
  string()
    .required(`Polje ne smije biti prazno`)
    .typeError(`${name || 'Polje'} ne smije biti prazno`);

export const optionalString = () => string().transform(emptyStringToNull).nullable();

export const requiredAlphaString = () =>
  string()
    .required(`Polje ne smije biti prazno`)
    .matches(/^[abcćčdđefghijklmnopqrsštuvwxyzž ]+$/i, 'Smije sadržavati samo slova');

export const requiredBoolean = () => boolean().required('').oneOf([true], '');

export const optionalBoolean = () => boolean().oneOf([true, false]);

export const requiredPhone = () =>
  string()
    .required('Polje ne smije biti prazno')
    .test('len', 'Unesite ispravan broj telefona', (phone) => checkPhone(phone));

export const optionalPhone = () =>
  string()
    .test('len', 'Unesite ispravan broj telefona', (phone) => checkPhone(phone))
    .nullable();

export const requiredPhoneLoose = () => string().test('len', 'Unesite ispravan broj telefona', (val) => val.length > 8);
export const optionalPhoneLoose = () =>
  string().test('len', 'Unesite ipravan broj telefona', (phone) => phone.length === 0 || phone.length > 8);

export const optionalEmail = () => string().email('Molimo unesite ispravan email').nullable();
