import { FiCheck } from 'react-icons/fi';
import { InputAdornment } from '@mui/material';

const commonProps = (name, { control, formState }, helperText) => {
  const { errors } = formState;
  const error = errors && !!errors[name];
  return {
    key: name,
    control,
    error,
    helperText: error ? `${errors[name].message} ${helperText || ''}` : '',
    fullWidth: true,
    variant: 'outlined',
  };
};

export const commonInputProps = ({ invalid, isDirty, isTouched }, value, inputProps = {}, gutter = false) => {
  return (isTouched || isDirty) && !invalid && value
    ? {
        endAdornment: (
          <InputAdornment position="end" sx={{ mr: gutter ? 3 : 0 }}>
            <FiCheck color="green" size={24} />
          </InputAdornment>
        ),
        sx: { bgcolor: 'success.light' },
      }
    : { ...inputProps };
};

export default commonProps;
