import { Form } from '@components/rhf-mui5';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { FiChevronLeft } from 'react-icons/fi';
import useFormContext from '../hooks/useFormContext';
import useServerSideData from '../hooks/useServerSideData';
import sanitize from '../utils/sanitize';
import FormHeader from './FormHeader';

const isBrowser = typeof window !== 'undefined';

export const WizardStep = ({ children }) => children;

const Wizard = ({ onSubmit, legal, children }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const { state, dispatch } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const methods = useForm({
    defaultValues:
      isBrowser && localStorage.getItem('formValues') !== null ? JSON.parse(localStorage.getItem('formValues')) : state,
    mode: 'onBlur',
    resolver: yupResolver(step.props.schema),
  });

  const { isSubmitting } = methods.formState;

  const next = (values) => {
    dispatch(values);
    if (isBrowser) localStorage.setItem('formValues', JSON.stringify({ ...values }));
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    if (isBrowser) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const previous = (values) => {
    const sanitized = sanitize(values);
    dispatch(sanitized);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values) => {
    try {
      if (step.props.onSubmit) {
        await step.props.onSubmit(values);
      }

      if (isLastStep) {
        if (isBrowser) localStorage.removeItem('formValues');
        return onSubmit(values);
      } else {
        if (isBrowser && sessionStorage.getItem('redirectValue')) {
          dispatch({ reset: true });
          localStorage.clear();
          sessionStorage.clear();
          navigate(`/zahtjev-primljen-postojeci`, { replace: true });
        } else {
          next(values);
        }
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error?.response?.data?.error) {
        const { message, sentry } = error.response.data.error;
        errorMessage = `${message} - ${sentry}`;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const alertUser = (e) => {
      if (stepNumber > 0) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('onbeforeunload', alertUser);
    return () => {
      window.removeEventListener('onbeforeunload', alertUser);
    };
  }, [stepNumber]);

  useServerSideData(methods.reset);

  return (
    <Box maxWidth={576} mx={['auto', 'auto', 'auto', 0]} height="100%">
      <FormHeader step={stepNumber} />
      <Box px={1}>
        <Form methods={methods} onSubmit={handleSubmit} spacing={isLastStep ? 0 : 2}>
          {step.props.children}
          <Box display="flex" mt={2} mb={1}>
            {stepNumber > 0 && (
              <Button variant="outlined" size="large" onClick={() => previous(methods.getValues())} sx={{ mr: 1 }}>
                <FiChevronLeft size={24} />
              </Button>
            )}
            <LoadingButton
              id={`korak-${stepNumber + 1}`}
              variant="contained"
              type="submit"
              size="large"
              loading={isSubmitting}
              fullWidth
            >
              {isLastStep ? `Pošalji zahtjev` : `Sljedeći korak`}
            </LoadingButton>
          </Box>
          {/* Pipeline trigger */}
        </Form>
        {stepNumber === 0 && legal}
      </Box>
    </Box>
  );
};

export default Wizard;
