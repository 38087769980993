import React from 'react';
import { useFormContext } from 'react-hook-form';
import commonProps from './commonProps';

const FormConnect = ({ inputProps = {}, children }) => {
  const methods = useFormContext();
  return React.Children.map(children, (child) => {
    return child?.props?.name
      ? React.createElement(child.type, {
          ...{
            ...child.props,
            ...commonProps(child.props.name, methods),
            ...inputProps,
          },
        })
      : child;
  });
};

export default FormConnect;
