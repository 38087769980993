import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { commonInputProps } from './commonProps';

const PhoneInput = ({ name, control, ...rest }) => {
  return (
    <Controller
      render={({ field, fieldState }) => (
        <NumberFormat
          {...field}
          {...rest}
          format="+387 ## ### ####"
          mask=""
          type="tel"
          customInput={TextField}
          allowEmptyFormatting
          variant="outlined"
          InputProps={commonInputProps(fieldState, field.value, rest.InputProps)}
        />
      )}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

export default PhoneInput;
