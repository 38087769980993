import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { RecoilRoot } from 'recoil';
import LoanExampleCard from '../components/LoanExampleCard';
import LoanWizardForm from '../components/LoanWizardForm';
import Section from '../components/Section';
import Seo from '../components/Seo';
import Layout from '../layout';

// -----

const IndexPage = ({ data }) => {
  const { strapiWebZahtjev } = data;

  return (
    <RecoilRoot>
      <Layout>
        <Seo title="Zahtjev za kredit" />
        <HeroSection>
          <HeroImage />
          <LoanWizardForm />
        </HeroSection>
        <Section>
          <Typography variant="h4" align="center">
            {strapiWebZahtjev.karakteristikeKreditaNaslov}
          </Typography>
          <LoanAttributesBox>
            {strapiWebZahtjev.karakteristikeKredita.map(({ id, naslov, podnaslov }) => (
              <Box key={id} p={4}>
                <Typography variant="h1" color="primary" gutterBottom>
                  {naslov}
                </Typography>
                <Typography variant="body1">{podnaslov}</Typography>
              </Box>
            ))}
          </LoanAttributesBox>
        </Section>
        <Section>
          {strapiWebZahtjev.koraci.map(({ id, naslov, opis, slika }, i) => (
            <AlternatingBox key={id} index={i}>
              <GatsbyImage alt="" image={slika.localFile.childImageSharp.gatsbyImageData} loading="lazy" />
              <Box>
                <Typography variant="h4" gutterBottom>
                  {naslov}
                </Typography>
                <Typography variant="subtitle1">{opis}</Typography>
              </Box>
            </AlternatingBox>
          ))}
        </Section>
        <Section>
          <BannerBox>
            <Box flex="1" minWidth={270} maxWidth={620} my="auto" p={5}>
              <Typography variant="h3" color="inherit">
                {strapiWebZahtjev.promocija.naslov}
              </Typography>
              <Typography variant="subtitle1" color="inherit" gutterBottom>
                {strapiWebZahtjev.promocija.opis}
              </Typography>
              <Button variant="contained" color="secondary">
                Apliciraj odmah
              </Button>
            </Box>
            <Box flex="1" minWidth={[270, 440]} maxWidth={460} ml="auto" mt="auto">
              <GatsbyImage
                loading="lazy"
                image={strapiWebZahtjev.promocija.slika.localFile.childImageSharp.gatsbyImageData}
                alt=""
              />
            </Box>
          </BannerBox>
        </Section>
        <Section maxWidth={720}>
          <Typography variant="h4" sx={{ mb: 6 }}>
            Česta pitanja i odgovori
          </Typography>
          {strapiWebZahtjev.cestaPitanja.map(({ id, pitanje, odgovor }) => (
            <Accordion key={id} square>
              <AccordionSummary>{pitanje}</AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">{odgovor}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Section>
        <Box bgcolor="background.neutral" width="100%">
          <Section maxWidth={1096}>
            <LoanExampleBox gap={8}>
              {strapiWebZahtjev.reprezentativniPrimjerMikrokredita.map(({ id, iznos, stavkeMikrokredita }, i) => (
                <LoanExampleCard key={id} amount={iznos} items={stavkeMikrokredita} contrast={i % 2 ? 0 : 1} />
              ))}
            </LoanExampleBox>
          </Section>
        </Box>
      </Layout>
    </RecoilRoot>
  );
};

const HeroSection = styled(Box)`
  min-height: 100vh;
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  ${({ theme }) => `
    padding-top: ${theme.spacing(3)};
    ${theme.breakpoints.up('lg')} {
      padding-top: 6vh;
    }
    `}
`;

const HeroImage = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 33%;
  min-width: 440px;
  border-bottom-left-radius: 25%;
  background-color: ${({ theme }) => theme.palette.primary.light};
  background-image: url('https://i.imgur.com/TZHYwjQ.png');
  background-repeat: no-repeat;
  background-size: cover;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: none;
  }
`;

const LoanAttributesBox = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
`;

const AlternatingBox = styled(Box)`
  ${({ theme, index }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: ${index % 2 ? 'row-reverse' : 'row'};
  gap: ${theme.spacing(3)};
  & > div {
    flex: 1;
    min-width: 270px;
    max-width: 540px;
  }
  margin-bottom: ${theme.spacing(6)};
  :last-child {
    margin-bottom: 0;
  }
  `}
`;

const BannerBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 460px;
  border-radius: 16px;
  color: #fff;
  background: linear-gradient(#0961b0 40%, #063e71);
`;

const LoanExampleBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    ${({ theme }) => theme.breakpoints.down('lg')} {
      margin: 0 auto;
    }
  }
`;

export const query = graphql`
  query IndexQuery {
    strapiWebZahtjev {
      karakteristikeKreditaNaslov
      karakteristikeKredita {
        id
        naslov
        podnaslov
      }
      koraci {
        id
        naslov
        opis
        slika {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 95, width: 540, placeholder: NONE)
            }
          }
        }
      }
      promocija {
        id
        naslov
        opis
        slika {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 95, width: 460, placeholder: NONE)
            }
          }
        }
      }
      cestaPitanja {
        id
        odgovor
        pitanje
      }
      reprezentativniPrimjerMikrokredita {
        id
        iznos
        stavkeMikrokredita {
          id
          labela
          vrijednost
          bezIkone
          naglaseno
        }
      }
    }
  }
`;

export default IndexPage;
