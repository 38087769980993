import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import axios from '../utils/axios';
import useFirebaseAuth from './useFirebaseAuth';
import useFormContext from './useFormContext';

const useServerSideData = (reset) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, signOut } = useFirebaseAuth();
  const { dispatch, state } = useFormContext();
  const { loanPurpose } = state;

  // If api is unavailable the fetching below will enter an infinite loop
  // This throttles it to attempt to reconnect every 10 sec
  useInterval(() => {
    if (error) setError(null);
  }, 10000);

  useEffect(() => {
    const getInProgressApplication = async () => {
      console.log('Fetching server side...');
      try {
        setLoading(true);
        const { data } = await axios.get(`/wizard/form-data`);

        if (!data.loanPurpose) await signOut();
        else {
          dispatch(data);
          reset(data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user && !loanPurpose && !loading && !error) getInProgressApplication();
  }, [user, dispatch, loanPurpose, loading, error, reset, signOut]);
};

export default useServerSideData;
