import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsPerson } from 'react-icons/bs';
import { FiCheckCircle } from 'react-icons/fi';
import { HiOutlineClipboardList } from 'react-icons/hi';
import React from 'react';
import { RiAttachment2 } from 'react-icons/ri';
import styled from '@emotion/styled';

const Header = ({ step }) => {
  const { palette } = useTheme();

  const stepData = [
    { icon: <BsPerson size={24} />, title: 'Iznos i rok', subtitle: 'Iznos i rok' },
    {
      icon: <AiOutlineInfoCircle size={24} />,
      title: 'Osnovne info.',
      subtitle: 'Osnovne info.',
    },
    {
      icon: <HiOutlineClipboardList size={24} />,
      title: 'Kreditna analiza',
      subtitle: 'Kreditna analiza',
    },
    { icon: <RiAttachment2 size={24} />, title: 'Dokumenti', subtitle: 'Dokumenti' },
  ];

  return (
    <Box display="flex" borderRadius={3} py={2} px={[0, 2]} mb={3} boxShadow={`0px 4px 50px rgba(1, 3, 60, 0.08)`}>
      {stepData.map(({ title, icon }, index) => {
        let component;
        const active = index === step ? 1 : 0;
        const last = index < stepData.length - 1;
        if (active)
          component = (
            <Box display="flex" alignItems="center" mr="auto" px={2}>
              <Avatar sx={{ mr: 2, p: 1, bgcolor: 'primary.main' }}>{icon}</Avatar>
              <Box display="flex" flexDirection="column">
                <Typography variant="overline">Korak {step + 1}/4</Typography>
                <Typography variant="body2" color="textPrimary">
                  {title}
                </Typography>
              </Box>
            </Box>
          );
        else if (!active && step > index) component = <FiCheckCircle size={24} color={palette.secondary.main} />;
        else component = icon;

        return (
          <StepIconContainer key={title} active={active} last={last?.toString()}>
            {component}
          </StepIconContainer>
        );
      })}
    </Box>
  );
};

const StepIconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ced5e2;
  border-image: linear-gradient(transparent,#ced5e2 25%, transparent) 1;
  ${({ active, theme, last }) => `
    flex: ${active ? 2 : 1};
    border-right: ${last ? `1px solid` : 0};
    ${theme.breakpoints.down('sm')} {
      flex: ${active ? 3 : 1};
    }
  `}
  }
`;

export default Header;
