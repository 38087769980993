import { array, object, ref } from 'yup';
import {
  checkJmbg,
  optionalBoolean,
  optionalEmail,
  optionalNullableNumber,
  optionalNumber,
  optionalPhone,
  optionalString,
  requiredAlphaString,
  requiredNullableNumber,
  requiredNumber,
  requiredPhone,
  requiredPhoneLoose,
  requiredString,
} from './helpers';

export const loginSchema = object().shape({
  email: requiredString().email('Unesite ispravan email'),
  password: requiredString(),
});

export const step1Schema = object({
  amount: requiredNumber().min(10999, `Minimalna vrijednost je 11000`).max(15000, `Maksimalna vrijednost je 15000`),
  repaymentPeriod: requiredNumber().min(3, `Minimalna vrijednost je 3`).max(48, `Maksimalna vrijednost je 48`),
  loanPurpose: requiredString('Odabrati namjenu'),
  repaymentLoan: requiredString(),
});

export const step2Schema = object({
  firstName: requiredAlphaString(),
  lastName: requiredAlphaString(),
  fathersName: requiredAlphaString(),
  jmbg: requiredString().test('len', 'Neispravan JMBG', (jmbg) => checkJmbg(jmbg)),
  lk: requiredString().test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
  address: requiredString(),
  municipality: requiredString(),
  localCommunity: requiredString(),
  mobilePhone: requiredPhone(),
  landlinePhone: optionalPhone(),
  email: optionalEmail(),
});

export const step3Schema = object({
  martialStatus: requiredString(),
  employmentStatus: requiredString(),
  employer: requiredString().max(80, `Maksimalno dozvoljeno 80 karaktera`),
  urbanOrRural: requiredString(),
  householdSize: requiredNullableNumber(),
  monthlyIncome: requiredNullableNumber(),
  monthlyHouseholdIncome: requiredNullableNumber(),
  monthlyHouseholdExpenses: requiredNullableNumber(),
  clientHasExistingLoan: optionalBoolean(),
  householdHasExistingLoan: optionalBoolean(),
  householdExistingLoanAmount: requiredNullableNumber(),
});

export const step4Schema = object({
  lkFiles: array().min(1, 'Obavezna dokumentacija').optional(),
  residenceProofFiles: array().min(1, 'Obavezna dokumentacija').optional(),
  incomeProofFiles: array().min(1, 'Obavezna dokumentacija').optional(),
});

export const loanOfficerSchema = object({
  firstName: requiredAlphaString(),
  lastName: requiredAlphaString(),
  fathersName: requiredString('Ime oca'),
  jmbg: requiredString().test('len', 'Neispravan JMBG', (jmbg) => checkJmbg(jmbg)),
  lk: requiredString('Broj LK').test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
  address: requiredString(),
  mobilePhone: requiredPhone(),
  landlinePhone: optionalPhone(),
  cipsAddress: requiredString(),
  cipsMunicipality: requiredString(),
});
export const loanOfficerSchemaLoose = object({
  firstName: requiredAlphaString(),
  lastName: requiredAlphaString(),
  fathersName: requiredString('Ime oca'),
  jmbg: requiredString().test('len', 'Neispravan JMBG', (jmbg) => checkJmbg(jmbg)),
  lk: requiredString('Broj LK').test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
  address: requiredString(),
  mobilePhone: requiredPhoneLoose(),
  landlinePhone: optionalPhone(),
});

export const loanOfficerSchemaCodebtor = object({
  firstName: requiredAlphaString(),
  lastName: requiredAlphaString(),
  jmbg: requiredString().test('len', 'Neispravan JMBG', (jmbg) => checkJmbg(jmbg)),
  lk: requiredString('Broj LK').test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
});

export const loanOfficerSchemaAnalysis = object({
  provableClientIncome: requiredNullableNumber(),
  unprovableClientIncome: requiredNullableNumber(),
  provableHouseholdIncome: requiredNullableNumber(),
  unprovableHouseholdIncome: requiredNullableNumber(),
  totalHouseholdIncome: requiredNullableNumber(),
  totalHouseholdCosts: requiredNullableNumber(),
  totalHouseholdLoan: requiredNullableNumber(),
});

export const officerAccountSchema = object({
  account: requiredString().test('len', 'Mora sadržavati 16 karaktera', (val) => val && val.length === 16),
});

export const allInputsSchema = object({
  repaymentPeriod: requiredNumber().min(3, `Minimalna vrijednost je 3`).max(48, `Maksimalna vrijednost je 48`),
  recommendedRepaymentPeriod: optionalNumber()
    .min(0, `Unesite broj veci od 0`)
    .max(60, `Unesite broj manji od 48`)
    .when('admin', { is: true, then: requiredNumber('Predloženi broj rata') }),
  amount: requiredNumber().min(10999, `Minimalna vrijednost je 11000`).max(15000, `Maksimalna vrijednost je 15000`),
  recommendedAmount: optionalNumber()
    .min(0, `Unesite broj veci od 0`)
    .max(ref('amount'), `Broj mora biti manji od iznosa`)
    .when('admin', { is: true, then: requiredNumber('Predloženi iznos') }),
  loanProduct: optionalString().when('admin', { is: true, then: requiredString('Kreditni proizvod') }),
  recommendedGracePeriod: optionalNullableNumber().when('admin', {
    is: true,
    then: requiredNullableNumber('Predloženi grace period'),
  }),
  office: optionalString().when('admin', { is: true, then: requiredString('Kancelarija') }),
  loanPurpose: requiredString('Odabrati namjenu'),
  repaymentLoan: requiredString(),
  recommendedLoanPurpose: optionalString(),
  loanType: optionalString().when('admin', { is: true, then: requiredString('Tip kredita') }),
  payoutType: optionalString().when('admin', { is: true, then: requiredString('Vrsta isplate') }),
  firstName: requiredAlphaString(),
  lastName: requiredAlphaString(),
  fathersName: requiredAlphaString(),
  jmbg: requiredString().test('len', 'Neispravan JMBG', (jmbg) => checkJmbg(jmbg)),
  lk: requiredString().test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
  address: requiredString(),
  mobilePhone: requiredPhone(),
  landlinePhone: optionalPhone(),
  email: optionalEmail(),
  municipality: requiredString(),
  localCommunity: requiredString(),
  martialStatus: requiredString(),
  urbanOrRural: requiredString(),
  householdSize: requiredNullableNumber(),
  employmentStatus: requiredString(),
  employer: requiredString().max(80, `Maksimalno dozvoljeno 80 karaktera`),
  monthlyIncome: requiredNullableNumber(),
  monthlyHouseholdIncome: requiredNullableNumber(),
  monthlyHouseholdExpenses: requiredNullableNumber(),
  clientHasExistingLoan: optionalBoolean(),
  householdHasExistingLoan: optionalBoolean(),
  householdExistingLoanAmount: requiredNullableNumber(),
});

export const adminSchema = object({
  repaymentPeriod: requiredNumber().min(3, `Minimalna vrijednost je 3`).max(48, `Maksimalna vrijednost je 48`),
  recommendedRepaymentPeriod: optionalNumber()
    .min(0, `Unesite broj veci od 0`)
    .max(48, `Unesite broj manji od 48`)
    .when('admin', { is: true, then: requiredNumber('Predloženi broj rata') }),
  amount: requiredNumber().min(10999, `Minimalna vrijednost je 11000`).max(15000, `Maksimalna vrijednost je 15000`),
  recommendedAmount: optionalNumber()
    .min(0, `Unesite broj veci od 0`)
    .max(ref('amount'), `Broj mora biti manji od iznosa`)
    .when('admin', { is: true, then: requiredNumber('Predloženi iznos') }),
  loanProduct: optionalString().when('admin', { is: true, then: requiredString('Kreditni proizvod') }),
  recommendedGracePeriod: optionalNullableNumber().when('admin', {
    is: true,
    then: requiredNullableNumber('Predloženi grace period'),
  }),
  office: optionalString().when('admin', { is: true, then: requiredString('Kancelarija') }),
  loanPurpose: requiredString(),
  recommendedLoanPurpose: optionalString(),
  loanType: optionalString().when('admin', { is: true, then: requiredString('Tip kredita') }),
  payoutType: optionalString().when('admin', { is: true, then: requiredString('Vrsta isplate') }),
  firstName: requiredAlphaString(),
  lastName: requiredAlphaString(),
  fathersName: requiredAlphaString(),
  jmbg: requiredString().test('len', 'Neispravan JMBG', (jmbg) => checkJmbg(jmbg)),
  lk: requiredString().test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
  address: requiredString(),
  mobilePhone: requiredPhone(),
  landlinePhone: optionalPhone(),
  email: optionalEmail(),
  municipality: requiredString(),
  localCommunity: requiredString(),
  martialStatus: requiredString(),
  urbanOrRural: requiredString(),
  householdSize: optionalNullableNumber(),
  employmentStatus: requiredString(),
  employer: requiredString(),
  monthlyIncome: requiredNullableNumber(),
  monthlyHouseholdIncome: requiredNullableNumber(),
  monthlyHouseholdExpenses: requiredNullableNumber(),
  clientHasExistingLoan: optionalBoolean(),
  householdHasExistingLoan: optionalBoolean(),
  householdExistingLoanAmount: optionalNullableNumber(),
});

export function previousStepsValid(currentStep, data, withErrors = false) {
  let schema;
  if (currentStep === 2) schema = step1Schema;
  if (currentStep === 3) schema = step1Schema.concat(step2Schema);
  else if (currentStep === 4) schema = step1Schema.concat(step2Schema).concat(step3Schema);
  else if (currentStep === 5) schema = step1Schema.concat(step2Schema).concat(step3Schema).concat(step4Schema);
  return withErrors ? schema.validate(data, { abortEarly: false }) : schema.isValid(data);
}

export const schema = step1Schema.concat(step2Schema).concat(step3Schema);

export const companionClientSchema = object({
  lk: requiredString('Broj LK').test('len1', 'Mora sadržavati 9 karaktera', (val1) => val1 && val1.length === 9),
  address: requiredString(),
  mobilePhone: requiredPhoneLoose(),
  landlinePhone: optionalPhone(),
  email: optionalEmail(),
});

export const createSchemaFromOldSchema = (stepInput) =>
  object(
    Object.keys(allInputsSchema.fields).reduce((obj, key) => {
      if (stepInput.filter(({ name }) => name === key).length > 0) obj[key] = allInputsSchema.fields[key];
      return obj;
    }, {})
  );
