import React, { useState } from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';

import { RadioGroup } from '@components/rhf-mui5';
import { BsCheck, BsQuestion } from 'react-icons/bs';
import { FaViber } from 'react-icons/fa';
import { FiCamera, FiFolder } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import axios from '../utils/axios';
import { fileState } from './states';

const SSRComponent = React.lazy(() => import('./PrintJSSSR'));

const documents = [
  { value: 101, label: 'Lična karta', description: 'Slika ili skenirana prednja i zadnja strane lične karte' },
  { value: 103, label: 'Potvrda o boravku', description: 'Slika ili skenirana potvrda o boravku (CIPS)' },
  {
    value: 105,
    label: 'Dokaz o primanjima',
    description: 'Slika ili skenirana platna lista ili izvod sa tekućeg računa',
  },
];

const uploadTypes = [
  { icon: <FiCamera />, label: 'Kamera', color: '#E4572E' },
  { icon: <FiFolder />, label: 'Sa uređaja', color: '#F4D35E' },
];

const DropzoneComponent = ({ loanApplicationId, control, handleFiles }) => {
  const isSSR = typeof window === 'undefined';
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [documentType, setDocumentType] = useState(101);


  const [fileUploaded, setFileUploaded] = useRecoilState(fileState);

  const maxSize = 8097152;

  const onDrop = async (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('files', file);
      formData.append('documentType', documentType);
      formData.append('loanApplicationId', loanApplicationId);
    });

    setUploading(true);
    const { data } = await axios.post(`/documents/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    setUploading(false);

    setFiles((currentFiles) => [
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
      ...currentFiles,
    ]);
    setFileUploaded([...fileUploaded, acceptedFiles[0]]);
  };

  const onDropRejected = (fileRejections) => {
    enqueueSnackbar(`Maksimalna veličina dokumenta je 8MB`);
  };

  const { acceptedFiles, rejectedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    minSize: 0,
    maxSize,
  });

  const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

  const description = documents.find((document) => document.value === documentType)?.description;

  const printOptions = [
    { value: 1, text: 'Imam printer' },
    { value: 2, text: 'Nemam printer' },
  ];

  const hasPrinter = useWatch({
    control,
    name: 'printer',
  });

  const [showPrintOptions, setShowPrintOptions] = useState(false);

  const handleDocumentSelect = (value) => {
    setDocumentType(value);
    if (value === 117) setShowPrintOptions(true);
    else setShowPrintOptions(false);
  };

  return (
    <Box>
      {' '}
      {isFileTooLarge && `File is too large!`}
      <Box my={3}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Odaberite tip dokumenta
        </Typography>
        {documents.map(({ value, label }) => (
          <Chip
            key={value}
            label={label}
            size="small"
            onClick={() => handleDocumentSelect(value)}
            variant={documentType === value ? 'primary' : 'outlined'}
            color={documentType === value ? 'primary' : 'default'}
            sx={{ mr: 1, fontSize: '12px' }}
          />
        ))}
      </Box>
      <Box display={!showPrintOptions && 'none'}>
        <RadioGroup name="printer" label="Da li imate pri ruci printer?" options={printOptions} />
      </Box>
      <Box display={(!showPrintOptions || hasPrinter !== '1') && 'none'}>
        <Stepper orientation="vertical" sx={{ mt: 3 }}>
          <Step active>
            <StepLabel>
              <Typography variant="subtitle2" color="textPrimary" sx={{ ml: 1 }}>
                
                dokument.
              </Typography>
            </StepLabel>
          </Step>
          <Step active>
            <StepLabel>
              <Typography variant="subtitle2" color="textPrimary" sx={{ ml: 1 }}>
                Potpišite i uslikajte ga.
              </Typography>
            </StepLabel>
          </Step>
          <Step active>
            <StepLabel>
              <Typography variant="subtitle2" color="textPrimary" sx={{ ml: 1 }}>
                Priložite sliku dokumenta ispod.
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Box display={(!showPrintOptions || hasPrinter !== '2') && 'none'}>
        <Stepper orientation="vertical" sx={{ mt: 3 }}>
          <Step active>
            <StepLabel>
              <Typography variant="subtitle2" color="textPrimary" sx={{ ml: 1 }}>
                <Link onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                  Prepišite tekst na papir.
                </Link>
              </Typography>
              <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle sx={{ pr: '0!important' }}>
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <IoMdClose />
                    </IconButton>
                  </Box>
                </DialogTitle>
                <DialogContent sx={{ p: '0!important' }}>
                  <StaticImage src="../../static/saglasnost.jpg" alt="Zahtjeva za mikrokredit" />
                </DialogContent>
              </Dialog>
            </StepLabel>
          </Step>
          <Step active>
            <StepLabel>
              <Typography variant="subtitle2" color="textPrimary" sx={{ ml: 1 }}>
                Potpišite i uslikajte ga.
              </Typography>
            </StepLabel>
          </Step>
          <Step active>
            <StepLabel>
              <Typography variant="subtitle2" color="textPrimary" sx={{ ml: 1 }}>
                Priložite sliku potpisanog papira ispod.
              </Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Box
        mt={3}
        display="flex"
        flexDirection="column"
        borderRadius={2}
        justifyContent="center"
        alignItems="center"
        p={2}
        mb={4}
        border="1px dashed #dadada"
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps({ capture: 'environment' })} />
        <Typography variant="h6" color="primary">
          Priložite dokument ovdje, ili importujte preko:
        </Typography>
        <Typography variant="subtitle2" color="textPrimary">
          {description}
        </Typography>
        <Box display="flex" width={280} justifyContent="space-evenly" mt={2}>
          {uploadTypes.map(({ label, href, color, icon }) => (
            <Box key={label} alignItems="center" display="flex" flexDirection="column" justifyContent="center">
              <Avatar component={IconButton} sx={{ bgcolor: color, mb: 1 }} href={href} disableRipple>
                {icon}
              </Avatar>
              <Typography variant="caption" color="textPrimary">
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Button
        href="viber://chat/?number=38762991199"
        startIcon={<FaViber />}
        size="large"
        sx={{ color: '#7360F2', borderColor: '#7360F2' }}
        fullWidth
      >
        Ili pošaljite dokumentaciju preko Vibera
      </Button>
      <aside>
        {fileUploaded.map((file) => (
          <Box key={file.path} my={3} display="flex" justifyContent="space-between">
            <Box display="flex" gap={2}>
              <img src={file.preview} width="50" height="50" alt="" />
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1">{file.path} </Typography>
                <Typography variant="body2">{file.size} bytes</Typography>
              </Box>
            </Box>
            <Box alignSelf="center" pt={1}>
              <BsCheck size={22} color={palette.secondary.main} />
            </Box>
          </Box>
        ))}
        {uploading && (
          <Box my={3} display="flex" justifyContent="space-between">
            <Box display="flex" gap={2}>
              <Box
                width={50}
                height={50}
                border="1px solid lightgrey"
                borderRadius={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="primary" size="small" style={{ width: 25, height: 25 }} />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1">Slika se trenutno uploaduje...</Typography>
                <Typography variant="body2">0 bytes</Typography>
              </Box>
            </Box>
            <Box alignSelf="center" pt={1}>
              <BsQuestion size={22} color={palette.primary.main} />
            </Box>
          </Box>
        )}
      </aside>
    </Box>
  );
};



export default DropzoneComponent;
