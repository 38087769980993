import React from 'react';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { Checkbox as MuiCheckbox, FormControlLabel, Typography } from '@mui/material';

const StyledCheckbox = styled(MuiCheckbox)`
  ${({ theme, errored }) => errored && `color: ${theme?.palette?.error?.main}`}
`;

const Checkbox = ({ name, control, error, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            label={<Typography variant="body1">{label}</Typography>}
            control={
              <StyledCheckbox
                onChange={(e) => field.onChange(e.target.checked)}
                checked={!!field.value}
                errored={error ? 1 : undefined}
                color="primary"
              />
            }
          />
        );
      }}
      defaultValue={false}
    />
  );
};

export default Checkbox;
