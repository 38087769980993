import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useWatch } from 'react-hook-form';
import { Collapse } from '@mui/material';
import { FormConnect, Select } from '@components/rhf-mui5';

const LocalCommunitySelect = ({ control, defaultValue }) => {
  const { registry } = useStaticQuery(query);

  const selectedMunicipality = useWatch({
    control,
    name: 'municipality',
    defaultValue,
  });

  const options = registry.data.localCommunities.filter(({ municipality }) => municipality === selectedMunicipality);
  const visible = !!selectedMunicipality;

  return (
    <Collapse in={visible} timeout="auto" unmountOnExit>
      <FormConnect>
        <Select label="Mjesna zajednica" name="localCommunity" options={options} />
      </FormConnect>
    </Collapse>
  );
};

const query = graphql`
  query {
    registry {
      data {
        localCommunities {
          text
          value
          municipality
        }
      }
    }
  }
`;

export default LocalCommunitySelect;
