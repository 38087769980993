import { Box, Divider, Typography, useTheme } from '@mui/material';

import { IoIosCheckmarkCircle as CheckmarkIcon } from 'react-icons/io';
import React from 'react';
import styled from '@emotion/styled';
import selectClient from '../hooks/selectClient';

const CardTable = ({ amount, items, contrast }) => {
  return (
    <Box
      color={contrast ? 'textPrimary' : '#fff'}
      bgcolor={contrast ? '#fff' : 'primary.main'}
      p={[4, 8]}
      borderRadius={2}
      boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
    >
      <Typography variant="body1" color="inherit" align="center">
        Reprezentativni primjer mikrokredita
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
        <Typography variant="h2" color="inherit" display="inline">
          <strong>{amount} KM</strong>
        </Typography>
        <Typography variant="body1" color="inherit" sx={{ ml: 1 }}>
          Partner
          <br />
          {selectClient() === 'Partner' ? 'ONLINE' : 'KLIK'}
        </Typography>
      </Box>
      <Divider sx={{ m: 3 }} />
      {items.map(({ id, labela, vrijednost, bezIkone, naglaseno }) => {
        return (
          <Box key={id} display="flex" mb={1}>
            {!bezIkone && (
              <Box color="secondary.main" mr={1}>
                <CheckmarkIcon size={24} />
              </Box>
            )}
            <Typography
              variant="body1"
              color="inherit"
              sx={{ fontWeight: naglaseno ? 600 : 400, textDecoration: naglaseno ? 'underline' : 'none' }}
            >
              {labela}: <strong>{vrijednost}</strong>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const CardWrapper = styled(Box)`
  background-color: ${(props) => (props.bgcolor === 'blue' ? props.palette.background.table : props.bgcolor)};
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export default CardTable;
