import React from 'react';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import {
  RadioGroup as MuiRadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';

const RadioGroup = ({ options, name, control, ...rest }) => {
  return (
    <Controller
      render={({ field }) => (
        <StyledFormControl component="fieldset" variant="outlined">
          <FormLabel component="legend" focused={false} error={!!rest.error}>
            <Typography color="primary" variant="body1" sx={{ fontWeight: 500, mb: 1 }}>
              {rest.label}
            </Typography>
          </FormLabel>{' '}
          {rest.error && (
            <Typography variant="body2" color="error">
              {rest.helperText}
            </Typography>
          )}
          <MuiRadioGroup aria-label={name} {...field}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio disableRipple color="primary" />}
                label={option.text}
              />
            ))}
          </MuiRadioGroup>
        </StyledFormControl>
      )}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

const StyledFormControl = styled(FormControl)`
  width: 100%;
  padding: 0 1px;

  .MuiFormControlLabel-root {
    border: 1px solid #ecebea;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 12px;
    margin-left: 1px;
    text-transform: lowercase;

    :last-child {
      margin-bottom: 0;
    }
  }
  .MuiRadio-root {
    color: #ecebea;
  }

  .MuiFormControlLabel-root > .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiFormControlLabel-root > :first-letter {
    text-transform: uppercase;
  }
`;

export default RadioGroup;
