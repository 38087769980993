import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { commonInputProps } from './commonProps';

const Select = ({ options, name, control, ...rest }) => {
  return (
    <Controller
      render={({ field, fieldState }) => (
        <TextField
          select
          SelectProps={{
            native: true,
          }}
          InputProps={commonInputProps(fieldState, field.value, rest.InputProps, true)}
          {...field}
          {...rest}
        >
          <StyledOption aria-label="None" value="" />
          {options.map((option) => {
            return (
              <StyledOption key={option.value} value={option.value}>
                {option.text}
              </StyledOption>
            );
          })}
        </TextField>
      )}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

const StyledOption = styled.option`
  ${({ theme }) => `
    font-family: ${theme?.typography?.fontFamily};
  `}
`;

export default Select;
