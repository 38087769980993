import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Box, Link, Typography, Button, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FaQuestionCircle } from 'react-icons/fa';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useRecoilValue } from 'recoil';
import { Checkbox, Input, LoanInput, PhoneInput, RadioGroup, Select, Autocomplete } from '@components/rhf-mui5';
import { step4Schema, createSchemaFromOldSchema } from '@partner/validation';
import { step4Schema as step4SchemaMKD, createSchemaFromOldSchema as createSchemaFromOldSchemaMKD } from '@partnermkd/validation';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useFormContext from '../hooks/useFormContext';
import axios from '../utils/axios';
import Annuity from './Annuity';
import LocalCommunitySelect from './LocalCommunitySelect';
import DropzoneComponent from './Upload';
import Wizard, { WizardStep } from './Wizard';
import { fileLengthState } from './states';
import selectClient from '../hooks/selectClient';

const returnInputForGivenType = ({ type, label, name, options }, registry, state, index) => {
  switch (type) {
    case 'INPUT_NUMBER':
      return <Input label={label} name={name} type="number" key={index} />;
    case 'LOAN_INPUT':
      return (
        <div key={index}>
          <LoanInput 
            amountProps={{ 
                max: selectClient() == 'Partner' ? 10000 : 15000, 
                min: selectClient() == 'Partner' ? 450 : 11000, 
                step: 100 
            }} 
            repaymentProps={{ 
              max: selectClient() == 'Partner' ? 60 : 48, 
              min: 3 
            }} />
          <Annuity defaultValues={{ amount: state.amount, repaymentPeriod: state.repaymentPeriod }} />
        </div>
      );
    case 'INPUT':
      return <Input label={label} name={name} key={index} />;
    case 'PHONE_INPUT':
      return <PhoneInput label={label} name={name} key={index} />;
    case 'CHECKBOX':
      return <Checkbox label={label} name={name} key={index} />;
    case 'SELECT':
      return <Select name={name} label={label} options={registry?.[options] || []} key={index} />;
    case 'AUTOCOMPLETE':
      return (
        <Autocomplete
          label={label}
          name={name}
          freeSolo
          options={Array.from({ length: 7 }, (_, i) => i + 1)}
          key={index}
        />
      );
    case 'RADIO_GROUP':
      return <RadioGroup label={label} name={name} options={registry?.[options] || []} key={index} />;
    case 'LOCAL_COMMUNITY_SELECT':
      return <LocalCommunitySelect defaultValue={state.municipality} key={index} />;
    default:
      return <div key={index}></div>;
  }
};

const LoanWizardForm = () => {
  const { state, dispatch } = useFormContext();
  const { strapiKorak1, registry, allForm } = useStaticQuery(query);
  const [ready, setReady] = useState(false);
  const { user, signInWithCustomToken, signOut } = useFirebaseAuth();
  const [openDialog, setOpenDialog] = useState(false);

  const uploadedFiles = useRecoilValue(fileLengthState);

  const handleDialogClose = () => setOpenDialog(false);
  const handleDialogOpen = () => setOpenDialog(true);

  const returnFieldsForStep = (step) =>
    allForm.edges
      .filter(({ node }) => node.stepWeb === step)
      .reduce((arr, { node }) => {
        return [...arr, { ...node }];
      }, []);

  const stepOneInputs = returnFieldsForStep(1);
  const stepTwoInputs = returnFieldsForStep(2);
  const stepThreeInputs = returnFieldsForStep(3);

  const stepOneSchema = selectClient() == 'Partner' ? createSchemaFromOldSchema(stepOneInputs) : createSchemaFromOldSchemaMKD(stepOneInputs);
  const stepTwoSchema = selectClient() == 'Partner' ? createSchemaFromOldSchema(stepTwoInputs) : createSchemaFromOldSchemaMKD(stepTwoInputs);
  const stepThreeSchema = selectClient() == 'Partner' ? createSchemaFromOldSchema(stepThreeInputs) : createSchemaFromOldSchemaMKD(stepThreeInputs);

  useEffectOnce(() => {
    dispatch({ step1Timestamp: new Date().toISOString() });
  });

  const handleSubmit = async () => {
    handleDialogClose();
    await axios.post(`/wizard/confirm`);
    await signOut();
    dispatch({ reset: true });
    localStorage.clear();
    navigate(`/zahtjev-primljen`, { replace: true });
  };

  useEffect(() => {
    setReady(true);
  }, []);

  if (!ready)
    return (
      <Box
        sx={{
          display: 'flex',
          paddingLeft: { lg: '30%' },
          justifyContent: { xs: 'center', lg: 'flex-start' },
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Wizard
      onSubmit={handleDialogOpen}
      legal={
        <Typography variant="caption">
          Prihvatam opšte{' '}
          <Link href={strapiKorak1.usloviPoslovanja.fajl.localFile.publicURL} target="_blank">
            uslove poslovanja
          </Link>
          ,{' '}
          <Link href={strapiKorak1.pravilaPrivatnosti.fajl.localFile.publicURL} target="_blank">
            pravila privatnosti
          </Link>
          , i pročitao/la sam{' '}
          <Link href={strapiKorak1.SIL.fajl.localFile.publicURL} target="_blank">
            informacioni list
          </Link>
        </Typography>
      }
    >
      <WizardStep
        onSubmit={async (values) => {
          if (user) axios.patch(`/wizard/step1`, values);
          else dispatch({ step2Timestamp: new Date().toISOString() });
        }}
        schema={stepOneSchema}
      >
        {stepOneInputs.map((node, index) => returnInputForGivenType(node, registry.data, state, index))}
      </WizardStep>
      <WizardStep
        onSubmit={async (values) => {
          if (user) axios.patch(`/wizard/step2`, { ...values });
          else {
            const { data } = await axios.post(`/wizard/start`, {
              ...values,
              step1Timestamp: state.step1Timestamp,
              step2Timestamp: state.step2Timestamp,
            });

            if (typeof window !== 'undefined' && !localStorage.getItem('sentStepThreeMail')) {
              axios.post(`/wizard/akcija/slanje-mailova`, {
                loanFormData: {
                  ...values,
                },
                step: 3,
              });
            }

            if (data?.status === 'FORWARDED') {
              if (typeof window !== 'undefined') sessionStorage.setItem('redirectValue', true);
            } else {
              const { token, loanApplicationId } = data;
              dispatch({ loanApplicationId });
              await signInWithCustomToken(token);
            }
          }
        }}
        schema={stepTwoSchema}
      >
        {stepTwoInputs.map((node, index) => returnInputForGivenType(node, registry.data, state, index))}
      </WizardStep>
      <WizardStep
        onSubmit={async (values) => {
          if (user) {
            axios.patch(`/wizard/step3`, values);
            if (typeof window !== 'undefined' && !localStorage.getItem('sentStepFourMail'))
              axios.post(`/wizard/akcija/slanje-mailova`, {
                loanFormData: {
                  ...values,
                },
                step: 4,
              });

            if (typeof window !== 'undefined') localStorage.setItem('sentStepFourMail', true);
          }
        }}
        schema={stepThreeSchema}
      >
        {stepThreeInputs.map((node, index) => returnInputForGivenType(node, registry.data, state, index))}
        <Box mt={1}>
          <Box mt={1} textAlign="end">
            <Typography variant="caption" sx={{ mt: 1 }}>
              <FaQuestionCircle /> Ako nema postojećih zaduženja, unesite 0.
            </Typography>
          </Box>
        </Box>
      </WizardStep>
      <WizardStep schema={selectClient() == 'Partner' ? step4Schema : step4SchemaMKD}>
        <Box>
          <Typography variant="h5" color="primary" gutterBottom sx={{ fontWeight: 500 }}>
            Kreditna dokumentacija
          </Typography>
          <Typography variant="body2">
            Ubrzajte obradu Vašeg zahtjeva tako što ćete priložiti neophodnu kreditnu dokumentaciju.{' '}
          </Typography>
        </Box>
        <DropzoneComponent name="files" loanApplicationId={state.loanApplicationId} />

        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Pošaljite zahtjev</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {uploadedFiles < 4
                ? 'Nisu priloženi svi dokumenti! Ako niste priložili sve potrebne dokumente zahtjev se ne smatra kompletiranim.'
                : 'Potrebna dokumentacija je priložena. Potvrdite da želite poslati zahtjev!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="outlined" color="error">
              Nazad
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Da, želim da pošaljem zahtjev
            </Button>
          </DialogActions>
        </Dialog>
      </WizardStep>
    </Wizard>
  );
};

const query = graphql`
  query LoanForm {
    allForm(
      filter: { active: { eq: "D" }, userPrivilege: { eq: 1 }, type: { ne: "HEADING" } }
      sort: { fields: [stepWeb, stepOrderWeb] }
    ) {
      edges {
        node {
          formId
          active
          componentType
          type
          editable
          formValidationId
          label
          name
          options
          params
          stepWeb
          stepOrderWeb
          validations
          validationType
          width
        }
      }
    }
    registry {
      data {
        municipalities {
          text
          value
        }
        martialStatus {
          text
          value
        }
        employmentStatus {
          text
          value
        }
        urbanOrRural {
          text
          value
        }
        loanPurposes {
          text
          value
        }
        repaymentOptions {
          text
          value
        }
      }
    }
    strapiKorak1 {
      pravilaPrivatnosti {
        id
        naslov
        url
        fajl {
          localFile {
            url
            publicURL
          }
        }
      }
      SIL {
        id
        naslov
        url
        fajl {
          localFile {
            url
            publicURL
          }
        }
      }
      usloviPoslovanja {
        id
        naslov
        url
        fajl {
          localFile {
            url
            publicURL
          }
        }
      }
    }
    strapiKorak3 {
      postojeceZaduzenje {
        zaduzenje
        zaduzenjeDomacinstvo
        zaduzenjePodnosioc
      }
    }
    strapiKorak4 {
      lk {
        naslov
        podnaslov
        dugmeTekst
      }
      boravak {
        naslov
        podnaslov
        dugmeTekst
      }
      primanja {
        naslov
        podnaslov
        dugmeTekst
      }
      
    }
  }
`;

export default LoanWizardForm;
