import { FormConnect, Input, Slider, Stepper } from '.';
import styled from '@emotion/styled';
import { Box, InputAdornment, Typography } from '@mui/material';
import React from 'react';

const LoanInput = ({ amountProps, repaymentProps }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" gap={2} mb={3}>
        <Box flex="1" textAlign="center">
          <Typography color="textSecondary" sx={{ mb: 1 }}>
            Iznos kredita
          </Typography>
          <FormConnect>
            <AmountInput
              label=""
              name="amount"
              InputProps={{
                endAdornment: <InputAdornment position="end">KM</InputAdornment>,
              }}
            />
          </FormConnect>
        </Box>
        <Box flex="1" textAlign="center">
          <Typography color="textSecondary" sx={{ mb: 1 }}>
            Rok otplate
          </Typography>
          <FormConnect>
            <Stepper
              name="repaymentPeriod"
              label="Rok otplate"
              unit="mj."
              max={repaymentProps.max}
              min={repaymentProps?.min || 1}
            />
          </FormConnect>
        </Box>
      </Box>
      <FormConnect>
        <Slider name="amount" max={amountProps.max} min={amountProps.min} step={amountProps.step} />
      </FormConnect>
    </Box>
  );
};

const AmountInput = styled(Input)`
  .MuiInputBase-input {
    box-sizing: border-box;
    height: 52px;
    padding: 0;
    padding-left: 32px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 20px;
    }
    @media (max-width: 320px) {
      font-size: 16px;
    }
  }
`;

export default LoanInput;
