import { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

if (typeof window !== 'undefined') {
  firebase.initializeApp(firebaseConfig);
}

const useFirebaseAuth = () => {
  const [user, setUser] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const signIn = useCallback(async (email, password) => {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    await firebase.auth()?.currentUser?.linkWithCredential(credential);
  }, []);

  const signInWithCustomToken = (token) => firebase.auth().signInWithCustomToken(token);

  const signOut = useCallback(async () => {
    await firebase.auth().signOut();
    setUser(null);
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      setUser(firebaseUser);
      setIsAuthReady(true);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return { user, signIn, signOut, isAuthReady, signInWithCustomToken };
};

export default useFirebaseAuth;
