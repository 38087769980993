import React from 'react';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { Slider as MuiSlider, Box, Typography } from '@mui/material';

const Slider = ({ name, control, label, step = 1, max = 10000, min = 1 }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        let { value } = field;
        value = !isNaN(field.value) && value ? parseInt(field.value, 10) : 0;
        return (
          <>
            {label && (
              <Box mx={2}>
                <Typography variant="h5">{label}</Typography>
                <Typography variant="h3" color="primary">
                  {value || '-'}
                </Typography>
              </Box>
            )}
            <StyledSlider
              {...field}
              value={value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              valueLabelDisplay="off"
              min={min}
              max={max}
              step={step}
            />
          </>
        );
      }}
    />
  );
};

const StyledSlider = styled(MuiSlider)`
  ${({ theme }) => `
  .MuiSlider-root {
    color: ${theme.palette.primary.main};
    height: 8px;
  }
  .MuiSlider-thumb {
    height: 36px;
    width: 36px;
    background-color: ${theme.palette.secondary.main};
    &:focus, &:hover, &:active {
      box-shadow: inherit;
      height: 36px;
      width: 36px;
    }
  }
  .MuiSlider-track {
    height: 6px;
    borderRadius: 5px;
  }
  .MuiSlider-rail {
    height: 6px;
    borderRadius: 5px;
    background-color:  ${theme.palette.divider};
  }
   `}
`;

export default Slider;
