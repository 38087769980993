import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

const AutocompleteRhf = ({ options, name, freeSolo, control, ...rest }) => {
  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          {...field}
          freeSolo
          getOptionLabel={(option) => String(option)}
          onInputChange={(e, value) => {
            if (value != field.value) field.onChange(value);
          }}
          onChange={(e, value) => {
            if (value != field.value) field.onChange(value);
          }}
          options={options}
          renderInput={(params) => <TextField {...rest} {...params} />}
        />
      )}
      name={name}
      control={control}
      defaultValue={null}
    />
  );
};

export default AutocompleteRhf;
